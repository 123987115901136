export const environment = {
  production: true,
  apiUrl: 'https://i40testbeta.servizi.mquadro.net/api/v1',//'${apiUrl}',//'https://i40testbeta.servizi.mquadro.net/api/v1',
};

export const service_order = {
  production: true,
  defaultGridColumns: ['ods_id', 'description', 'start_date', 'end_date', 'ts_ods_start', 'ts_ods_end', 'duration', 'assignedLicensePlate', 'status', 'group'],//['ods_id', 'description', 'extradata', 'start_date', 'end_date', 'ts_ods_start', 'ts_ods_end', 'assignedDriver', 'assignedLicensePlate', 'status', 'duration', 'group', 'operations'],
  gridColumns: '${gridColumns}'
};
